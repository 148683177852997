import axios from "axios";
import Cognito from "../utility/CognitoAuth";
import CookieService from "./CookieService";

const base_api =
  "https://61imkkyyn9.execute-api.us-east-1.amazonaws.com/dev";

async function defaultRequest() {
  const idToken = await Cognito.getIdToken();
  let req = axios.create({
    baseURL: base_api,
    headers: { Authorization: idToken },
  });

  return req;
}

const TicketService = {
  getTickets: async function (page = 1, pageSize = 10, filters = {}) {
    // base API URL

    const orgId = CookieService.getCookie("OrganizationId");
    const baseUrl = `${base_api}/tickets/${orgId}`;

    // construct the query string using the passed parameters
    const queryParams = {
      page,
      pageSize,
      filter: JSON.stringify(filters),
      internal_use: false,
    };

    const queryString = new URLSearchParams(queryParams).toString();
    const req = await defaultRequest();
    return req.get(`${baseUrl}?${queryString}`);
  }
};

export default TicketService;
