import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { appState } from "../../AppState";
import ConnectriaTicketsService from "../../services/ConnectriaTicketsService";
import { getReadableDate } from "../../utility/DateUtil";
import useAsyncEffect from "../../utility/use-async-effect";
import { AdvancedTicketSearch } from "./AdvancedTicketSearch";
import {
  NewTabLink,
  TicketBladeContainer,
} from "./ConnectriaTicketsBlade";
import useTriaTicketsState, { createdBy } from "./ConnectriaTicketsState";
// reactstrap components
import { FormGroup, Input, UncontrolledTooltip } from "reactstrap";
import { CSVUtility } from "../../utility/table-to-csv";
import NewTicketButton from "../SupportAndTicketing/NewTicketButton";
import { ClipboardCopyButton } from "../misc";

import momentLocalizer from "react-widgets-moment";
import "react-widgets/dist/css/react-widgets.css";
import "../../assets/scss/search/SearchBar.scss";
import TicketService from "../../services/TicketService";
import TicketPriorityBadge from "./TicketPriorityBadge";
import TicketStatusBadge, { getTicketStatusClassName } from "./TicketStatusBadge";
import { PROVIDERS } from "./constants";

moment.locale("en");
momentLocalizer();

const TICKETPULLSIZE = 25;
// TODO: add LE status filters
const statusFilters = [
  [""],
  ["New"],
  ["Open"],
  ["WaitOnCust"],
  ["Scheduled"],
  ["Resolved"],
  ["Closed"],
  ["Suspended"],
  ["Cancelled"],
];

function showItemBlade(ticketNo, ticketProvider, stateAppActions) {
  stateAppActions.setBlade({
    title: (
      <span>
        {"#" + ticketNo}{" "}
        <ClipboardCopyButton
          value={`${window.location.origin}/app/view/ticket/${ticketNo}`}
          tooltipMessage={"Copy ticket link"}
          target="copy-ticket-link"
          alertMessage={`Ticket #${ticketNo} link copied.`}
        />
        <NewTabLink
          link={`/app/view/ticket/${ticketNo}`}
          tooltipMessage={"Open In New Tab"}
          target="open-new-tab-link"
          alertMessage={""}
        />
      </span>
    ),
    visible: true,
    content: (
      <TicketBladeContainer id={ticketNo} ticketProvider={ticketProvider} />
    ),
  });
}

function InboxItems(props) {
  const items = props.tickets;
  const [isLoading, setIsLoading] = useState(true);
  const [stateApp] = appState();
  const [, setTypes] = useState();
  const [, setPriorities] = useState();
  const source = axios.CancelToken.source();
  useAsyncEffect(
    async (isMounted) => {
      try {
        setIsLoading(true);
        let resp = await ConnectriaTicketsService.ticketTypes({
          cancelToken: source.token,
        });
        setPriorities(ConnectriaTicketsService.ticketPriorities());
        if (!isMounted()) return;
        setTypes(resp);
        setIsLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          throw error;
        }
      }
    },
    () => {
      source.cancel();
    },
    []
  );

  if (isLoading) {
    return null;
  } else {
    return (
      <>
        {items.map((item, i) => {
          const statusClassName = getTicketStatusClassName({
            provider: item.provider,
            status: item.status,
          });

          return (
            <button
              type="button"
              onClick={(e) =>
                e.target.className.includes("copy-clipboard")
                  ? null
                  : showItemBlade(
                    item.id,
                    item.provider,
                    props.stateAppActions
                  )
              }
              key={item.id}
              className={`mt-1 mb-1 list-group-item list-group-item-action pointer flex-column align-items-start ${statusClassName}`}
            >
              <div className="d-flex w-100 justify-content-between">
                <div className="row">
                  <h5 className="mb-1 d-block d-sm-none">#{item.id}</h5>
                  <h4 className="mb-1 d-none d-md-block">#{item.id}</h4>
                  <ClipboardCopyButton
                    className="ml-1 mt-1"
                    value={`${window.location.origin}/app/view/ticket/${item.id}`}
                    tooltipMessage={"Copy ticket link"}
                    alertMessage={`Ticket #${item.id} link copied.`}
                    idx={i}
                  />
                  <NewTabLink
                    itemId={item.id}
                    link={`/app/view/ticket/${item.id}`}
                    tooltipMessage={"Open In New Tab"}
                    alertMessage={""}
                  />
                </div>
                <small className="d-block d-sm-none pull-right">
                  Updated:{" "}
                  {getReadableDate(
                    item.lastUpdate,
                    moment(
                      item.lastUpdate,
                      "YYYY-MM-DDThh:mm:ss.SSSZ"
                    ).fromNow()
                  )}
                </small>
                <small className="d-block d-sm-none">
                  Created:{" "}
                  {getReadableDate(
                    item.created,
                    moment(item.created, "YYYY-MM-DDThh:mm:ss.SSSZ").fromNow()
                  )}
                </small>
                <span className="pull-right d-none d-md-block">
                  Updated:{" "}
                  {getReadableDate(
                    item.lastUpdate,
                    moment(
                      item.lastUpdate,
                      "YYYY-MM-DDThh:mm:ss.SSSZ"
                    ).fromNow()
                  )}
                </span>
                <span className="d-none d-md-block">
                  Created:{" "}
                  {getReadableDate(
                    item.created,
                    moment(item.created, "YYYY-MM-DDThh:mm:ss.SSSZ").fromNow()
                  )}
                </span>
              </div>
              <p className="mb-1">{item.subject}</p>
              <div className="lead">
                <span className="">
                  <TicketStatusBadge
                    provider={item.provider}
                    status={item.status}
                  />
                </span>
                <div className="d-md-inline-block pl-lg-3 pt-1 pt-lg-0">
                  Owner:
                  <span className="pl-2">{createdBy(item.user)}</span>
                </div>
              </div>
            </button>
          );
        })}
      </>
    );
  }
}

const TicketExportButton = (props) => {
  const [gettingData, setGettingData] = useState(false);
  const [stateApp, stateAppActions] = appState();

  function getStatusValue(status) {
    if (status === "New") {
      return 1;
    } else if (status === "Open") {
      return 2;
    } else if (status === "WaitOnCust") {
      return 3;
    }
    return 4;
  }

  const exportTickets = async (statuses, filename_prefix) => {
    let tickets = await ConnectriaTicketsService.exportAllTickets();
    setGettingData(false);
    if (tickets.length === 0) return;

    // convert tickets to CSV format
    tickets = tickets.map((ticket) => {
      let created = moment(ticket.Created).format("MM/DD/YY hh:mm:ss");
      let lastUpdate = moment(ticket["Last Update On"]).format(
        "MM/DD/YY hh:mm:ss"
      );
      return {
        Id: ticket.Id,
        CustomerTicket: ticket.CustomerTicket,
        "Priority/Type": ticket.Priority + " / " + ticket.Type,
        Status:
          ticket.Status === "WaitOnCust" ? "Input Required" : ticket.Status,
        Owner: ticket.Owner,
        Team: ticket.Team,
        Subject: ticket.Subject,
        Created: created + " " + ticket["Last Update By"],
        "Last Update": created === lastUpdate ? null : lastUpdate,
        Devices: ticket.Devices,
        "Ticket Link": `${window.location.origin}/app/view/ticket/${ticket.Id}`,
      };
    });
    tickets.sort((a, b) => getStatusValue(a.Status) - getStatusValue(b.Status));

    const filename =
      stateApp.userInfo.organization_name +
      filename_prefix +
      moment().format("YYYY-MM-DD_hh-mm-ss") +
      ".csv";
    CSVUtility.downloadCSVFromJson(filename, tickets);

    stateAppActions.setAlert({
      content: <p style={{ color: "white" }}>Tickets successfully exported</p>,
      className: "success",
      visible: true,
    });
    setTimeout(() => {
      stateAppActions.setAlert((prevState) => ({
        ...prevState,
        visible: false,
      }));
    }, 2000);
  };

  return (
    <span className="ml-3 my-auto" style={{ fontSize: "15px" }}>
      {gettingData ? (
        <i className="fas fa-spinner fa-spin" id="ticket-export-loading" />
      ) : (
        <>
          <i
            className="fas fa-file-export"
            id="ticket-export-button"
            onClick={() => {
              setGettingData(true);
              exportTickets(
                [
                  "New",
                  "Open",
                  "Scheduled",
                  "WaitOnCust",
                  "Resolved",
                  "Closed",
                  "Suspended",
                  "Cancelled",
                ],
                "_All_Tickets_"
              );
            }}
          />
          <UncontrolledTooltip placement="right" target="ticket-export-button">
            Export All Tickets For 3 Months
          </UncontrolledTooltip>
        </>
      )}
    </span>
  );
};

function Inbox(props) {
  //plugin state
  const [state, stateActions] = useTriaTicketsState();
  //local state
  const [offset, setOffset] = useState(0);
  const [filterState, setFilterState] = useState({});
  const [advancedFilterState, setAdvancedFilterState] = useState({});
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const source = axios.CancelToken.source();

  const [isError, setIsError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [newSearchFilter, setNewSearchFilter] = useState({});
  const itemsPerPage = 25;

  const [totalItems, setTotalItems] = useState(0);

  const handleNextPage = () => {
    if (currentPage < Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const transformFilterState = (filterState) => {
    const transformedFilter = {
      searchTerm: filterState.keyword?.[0] || "",
      status: filterState.status?.[0] || "",
      ticketNumber: filterState.ticketNumber || "",
      requester_id: filterState.requester_id|| "",
      portal_user_id:filterState.portal_user_id || ""
    };

    return transformedFilter;
  };

  // fetch tickets
  useAsyncEffect(
    async (isMounted) => {
      let tickets = [];
      const clearInbox = true;

      // set loading state
      stateActions.setIsLoading(true);
      if (clearInbox) {
        stateActions.clearTickets();
      }

      try {
        const formattedFilters = transformFilterState(filterState);

        const searchFilterData =
          props.stateApp.orgType === PROVIDERS.CONNECTRIA &&
            !props.stateApp.isHybrid
            ? formattedFilters
            : newSearchFilter;
        const ticketsResponse = await TicketService.getTickets(
          currentPage,
          TICKETPULLSIZE,
          searchFilterData
        );

        if (!isMounted()) return;

        tickets = ticketsResponse.data.body.tickets || [];
        tickets = tickets.filter((ticket) => !ticket.internal_use || ticket.internal_use === "FALSE");

        setTotalItems(ticketsResponse.data.body.totalTickets);
        stateActions.setTickets(tickets);

        // update tickets state
        for (var i = 0; i < tickets.length; i++) {
          stateActions.addTicket(tickets[i]);
        }
        stateActions.setIsLoading(false);

        setOffset(TICKETPULLSIZE);
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          throw error;
        }
      } finally {
        if (isMounted()) {
          stateActions.setIsLoading(false);
        }
      }
    },
    () => {
      console.log("unmount canceled");
      source.cancel();
    },
    [currentPage, filterState, advancedFilterState, newSearchFilter]
  );

  return (
    <>
      {showAdvancedSearch && (
        <AdvancedTicketSearch
          inboxFilterState={advancedFilterState}
          setInboxFilterState={setAdvancedFilterState}
        />
      )}
      {!showAdvancedSearch && (
        <TicketFilters
          filterState={filterState}
          setFilterState={setFilterState}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          newSearchFilter={newSearchFilter}
          setNewSearchFilter={setNewSearchFilter}
        />
      )}
      <div className="list-group mt-1" id="connectria-db-tickets">
        <InboxItems
          tickets={state.tickets}
          stateApp={props.stateApp}
          stateAppActions={props.stateAppActions}
          statePlugin={props.statePlugin}
          statePluginActions={props.statePluginActions}
        />
      </div>

      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="btn btn-primary"
        >
          Previous
        </button>

        <span className="mx-2">
          Page {currentPage} of {Math.ceil(totalItems / itemsPerPage)} | Total
          Records: {totalItems}
        </span>

        <button
          onClick={handleNextPage}
          disabled={currentPage >= Math.ceil(totalItems / itemsPerPage)}
          className="btn btn-primary"
        >
          Next
        </button>
      </div>

      {!state.isLoading && isError && (
        <div className="text-center mt-4 text-bold">
          <i className="fas fa-exclamation-triangle"></i> Error Loading Tickets.
        </div>
      )}
      {!state.isLoading && !isError && state.tickets.length === 0 && (
        <div className="text-center mt-4 text-bold">
          No tickets found. Try adjusting your filter.
        </div>
      )}
      {state.isLoading && (
        <div className="text-center">
          <i
            className="fas fa-spinner m-3 mt-4 fa-spin"
            style={{ fontSize: "30px" }}
          ></i>
        </div>
      )}
    </>
  );
}

const ConnectriaTicketsDashboardTab = () => {
  //global state
  const [stateApp, stateAppActions] = appState();

  return (
    <>
      <h3 id="connectria-ticket-db-body-title">Ticketing Inbox</h3>
      <Inbox stateApp={stateApp} stateAppActions={stateAppActions}></Inbox>
    </>
  );
};

const TicketFilters = (props) => {
  const {
    filterState,
    setFilterState,
    currentPage,
    setCurrentPage,
    newSearchFilter,
    setNewSearchFilter,
  } = props;
  const [stateApp, stateAppActions] = appState();
  const [state, stateActions] = useTriaTicketsState();
  const [statusId, setStatusId] = useState("0");
  const [ticketNo, setTicketNo] = useState("");
  const [keyword, setKeyword] = useState("");
  const [searchName, setSearchName] = useState("");
  const [filterMine, setFilterMine] = useState(false);
  const [filterConnectriaMine, setFilterConnectriaMine] = useState(false);
  const [originalTickets, setOriginalTickets] = useState([]);
  useEffect(() => {
    const fetchTickets = async () => {
      try {
        if (!(stateApp.orgType === PROVIDERS.CONNECTRIA) || stateApp.isHybrid) {
          const ticketsResponse = await TicketService.getTickets(
            currentPage,
            TICKETPULLSIZE,
            newSearchFilter
          );
          const tickets = ticketsResponse?.data?.body?.tickets || [];
          setOriginalTickets(tickets.filter((ticket) => !ticket.internal_use || ticket.internal_use === "FALSE"));
        }
      } catch (error) {
        console.error("Error fetching tickets:", error);
      } 
    };

    fetchTickets();
  }, [currentPage, newSearchFilter]);

  useEffect(() => {
    const updateFilterState = async () => {
      try {
        const userId = await ConnectriaTicketsService.portalUserId();

        if (filterConnectriaMine) {
          setFilterState((prevState) => ({
            ...prevState,
            requester_id: stateApp.userInfo.external_id,
            portal_user_id: userId,
          }));
        } else {
          setFilterState((prevState) => ({
            ...prevState,
            requester_id: "",
            portal_user_id: "",
          }));
        }

        setCurrentPage(1);
      } catch (error) {
        console.error("Failed to update filter state:", error);
      }
    };

    updateFilterState();
  }, [filterConnectriaMine]);
  
  const handleInputChange = (e) => {
    setSearchName(e.target.value);
  };

  const handleTicketNumber = (e) => {
    setTicketNo(e.target.value);
  };

  const handleFilterMine = () => {
    setFilterMine((prevState) => !prevState);
  };

 const handleSearch = async () => {
  let userId = await ConnectriaTicketsService.portalUserId();
  setNewSearchFilter((prevFilter) => {
    const newFilter = {
      searchTerm: searchName,
      ticketNumber: ticketNo,
    };
    let customerId = stateApp.organization.customer_id;
    if (customerId?.toLowerCase().startsWith("le")) {
      customerId = customerId.slice(2);
    }
    if (filterMine) {
      return {
        ...prevFilter,
        ...newFilter,
        requester_id: stateApp.userInfo.external_id,
        ...(stateApp.orgType === PROVIDERS.CONNECTRIA || stateApp.isHybrid
          ? { portal_user_id: userId }
          : {})
      };
    }

    return {
      ...newFilter
    };
  });

  setCurrentPage(1);
};


  const handleStatusChange = (e) => {
    let newStatusId = e.target.value;
    setStatusId(statusId);
    stateActions.setStatusId(e.target.value);
    let sort_by = "created desc";
    if (newStatusId === 0) {
      sort_by =
        "case when status = 'New' then '1' " +
        "when status = 'Open' then '2' " +
        "when status = 'WaitOnCust' then '3' " +
        "when status = 'Scheduled' then '4' " +
        "else status end asc, created desc";
    }
    setCurrentPage(1);
    setFilterState({
      ...filterState,
      status: statusFilters[newStatusId],
      ticketNumber: ticketNo,
      sort_by: sort_by,
    });
  };

  const handleKeywordChange = (e, newKeyword) => {
    e.preventDefault();
    setCurrentPage(1);
    setFilterState({ ...filterState, keyword: [newKeyword] });
  };

  const handleConnectriaMyTickets = async () => {
    setFilterConnectriaMine((prevState) => !prevState);
    }

  const ticketEntry = (e) => {
    if (e) {
      e.preventDefault();
    }
    setCurrentPage(1);
    setFilterState({ ...filterState, ticketNumber: ticketNo });
  };

  const handleClearFilters = () => {
    setFilterMine(false);
    setSearchName('');
    setTicketNo('');
    setCurrentPage(1);
    setNewSearchFilter((prevFilter) => ({
      searchTerm: '',
      ticketNumber: '',
    }));

    stateActions.setTickets(originalTickets);
  };

  if (stateApp.orgType === PROVIDERS.CONNECTRIA && !stateApp.isHybrid) {
    return (
      <div className="row">
        <div className="row col-lg-4 col-sm-6 mt-2">
          <div className="col-6">
            <Input
              type="select"
              name="filterStatus"
              id="filterStatus"
              onChange={handleStatusChange}
              value={state.statusId}
            >
              <option value="0">All Open</option>
              <option value="1">New</option>
              <option value="2">Open</option>
              <option value="3">Input Required</option>
              <option value="4">Scheduled</option>
              <option value="5">Resolved</option>
              <option value="6">Closed</option>
              <option value="7">Suspended</option>
              <option value="8">Cancelled</option>
            </Input>
          </div>
          <div className="col-6">
            <form onSubmit={(e) => ticketEntry(e)}>
              <Input
                onChange={(e) => setTicketNo(e.target.value)}
                type="text"
                placeholder="Ticket #"
                value={ticketNo}
              />
            </form>
          </div>
        </div>

        <div className="d-flex flex-fill col-lg-auto col-sm-12 mt-2">
          <form
            className="flex-fill"
            onSubmit={(e) => handleKeywordChange(e, keyword)}
          >
            <Input
              onChange={(e) => setKeyword(e.target.value)}
              type="text"
              placeholder="Search Subject and Issue"
              value={keyword}
            />
          </form>
          <i
            className="fa fa-times mr-2"
            style={{
              marginLeft: -20 + "px",
              marginTop: 12 + "px",
              cursor: "pointer",
            }}
            onClick={(e) => {
              setKeyword("");
              handleKeywordChange(e, "");
            }}
          ></i>
        </div>
        <div
          className="col-lg-3 col-sm-6 row justify-content-between mt-2"
          style={{ maxWidth: "175px", marginLeft: "2px" }}
        >
          <FormGroup className="">
            <div className="position-relative form-check">
              <label className="form-check-label">
                <nobr>My Tickets</nobr>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={filterConnectriaMine}
                  onChange={handleConnectriaMyTickets}
                />
                <span className="form-check-sign">
                  <span className="check"></span>
                </span>
              </label>
            </div>
          </FormGroup>
          <div className="my-auto">
            <NewTicketButton className="connectria-tickets-inbox-btn">
              <i className="fas fa-plus" id="inbox-new-ticket-button"></i>
            </NewTicketButton>
            <UncontrolledTooltip
              placement="right"
              target="inbox-new-ticket-button"
            >
              Create a ticket
            </UncontrolledTooltip>
          </div>
          <TicketExportButton />
        </div>
      </div>
    );
  }

  return (
    <div className="main-container">
      {/* Search Component */}
      <div className="enhanced-search-bar">
        <input
          type="text"
          placeholder="Search by subject"
          value={searchName}
          onChange={handleInputChange}
        />
        <input
          onChange={handleTicketNumber}
          type="text"
          placeholder="Ticket #"
          value={ticketNo}
        />
        <div className="form-check">
          <label className="form-check-label">
            <nobr>My Tickets</nobr>
            <input
              type="checkbox"
              className="form-check-input"
              checked={filterMine}
              onChange={handleFilterMine}
            />
            <span className="form-check-sign">
              <span className="check"></span>
            </span>
          </label>
        </div>
      </div>
      <div className="button-group">
        <button className="search-button" onClick={handleSearch}>
          <i className="fa fa-search"></i> Search
        </button>
        <button className="clear-filters-button" onClick={handleClearFilters}>
          <i className="fa fa-times"></i> Clear Filters
        </button>

        <div className="my-auto">
          <NewTicketButton className="round-button">
            <i className="fas fa-plus" id="inbox-new-ticket-button"></i>
          </NewTicketButton>
          <UncontrolledTooltip
            placement="right"
            target="inbox-new-ticket-button"
          >
            Create a ticket
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
};

export { ConnectriaTicketsDashboardTab, Inbox };
